const useCheckout = () => {
  const { $api } = useNuxtApp()
  const submitedName = useState('submitedName', () => '')
  const scheduling = useState('scheduling', () => null)

  function selectService(service, page = 'book') {
    const cookie = useCookie('megeni-service-selected')
    const router = useRouter()

    cookie.value = {
      id: service.id,
      name: service.name,
      currency: service.currency,
      from_price_usd: service.from_price_usd,
      to_price_usd: service.to_price_usd,
      provider: {
        state: service.provider.state,
        name: service.provider.name,
        slug: service.provider.slug,
        cover: service.provider.cover ? { img: service.provider.cover.img} : null,
        location: {
          address: service.provider.location.address,
        },
      },
      instruction_note: service.instruction_note,
      scheduling_note: service.scheduling_note,
      scheduling_url: service.scheduling_url,
      delivery: service.delivery,
      delivery_areas: service.delivery_areas,
      physical_location: service.physical_location,
      address: service.address,
      slug: service.slug,
      protocolId: service.protocolId,
    }
    router.push(`/${page}`)
  }

  async function submitBooking(payload, loggedIn = false) {
    const apiUrl = loggedIn
      ? '/api/case/'
      : '/api/case/anon/'

    const response = await $api(apiUrl, {
      method: 'POST',
      body: payload
    })
    return response
  }

  return {
    submitedName,
    scheduling,
    selectService,
    submitBooking
  }
}

export default useCheckout